<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'
import { getTranslation, setShop } from '@/ts/utilities.ts'
import { MarkerData } from '@/interfaces/MapsViewInterface'
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))
const Modal = defineAsyncComponent(() => import('@/components/helpers/Modal.vue'))

interface Props {
	dealer: MarkerData
}

const props = defineProps<Props>()

const changingShop = ref(false)
const changingShopRedirectLink = ref('')
const changingShopRedirectText = ref('')

const dealer = props.dealer

const changeShop = async (dealer: MarkerData) => {
	try {
		const dealerLink = dealer.Link
		const data = await setShop(dealer, false)
		if (data.success) {
			if (!data.beforeRedirectConfirmation) {
				window.location.href = dealerLink
			} else {
				changingShop.value = true
				changingShopRedirectLink.value = data.redirectLink
				changingShopRedirectText.value = data.beforeRedirectConfirmationText
			}
		}
	} catch (error) {
		console.error('Error changing shop', error)
	}
}

const redirectShop = (redirectLink: string) => {
	location.href = redirectLink
}

function close() {
	changingShop.value = false
	if (document.documentElement.classList.contains('overflow-hidden') && document.documentElement.classList.contains('modal-open')) {
		document.documentElement.classList.remove('overflow-hidden', 'modal-open')
	}
}

</script>

<template>
	<div class="flex flex-col relative gap-4 justify-center bg-white hover:no-underline py-8 px-6 rounded-md hover:shadow">
		<address class="not-italic">
			<p class="text-lg">{{ dealer.ShopName }}</p>
			{{ dealer.Address }}<br>
			{{ dealer.PostalAndCity }}<br>
			{{ dealer.Phone }}<br>
			<a :href="`mailto:${dealer.Email}`" class="text-black underline">
				{{ dealer.Email }}
			</a>
		</address>
		<strong>
			{{ getTranslation('FindShopOpeningHoursHeader') }}
		</strong>
		<div v-if="dealer?.ShopInformationList?.length > 0">
			<div v-for="row in dealer.ShopInformationList"
				 class="flex gap-x-2">
				<div v-if="row.columnOne"
					 :class="row.columnTwo ? 'basis-1/2' : ''">
					{{ row.columnOne }}
				</div>
				<div v-if="row.columnTwo">{{ row.columnTwo }}</div>
			</div>
		</div>
		<div v-if="dealer?.ShopInformationText"
			 v-html="dealer?.ShopInformationText">
		</div>
		<div class="flex mt-auto">
			<RenderAction
				action="button"
				theme="primary"
				@click="changeShop(dealer)"
			>
				{{ getTranslation('FindShopCTA') }}
			</RenderAction>
		</div>

		<Modal v-if="changingShop" :show="changingShop" @close="changingShop = false">
			<template v-slot:modal-title>
				{{ getTranslation('Skift forhandler') }}
			</template>
			<template v-slot:content>
				<div class="p-8 w-full flex flex-col justify-center items-center gap-8 min-h-[50vh]">
					<div class="text-center">
						{{ changingShopRedirectText }}
					</div>
					<div class="flex items-center gap-2">
						<RenderAction
							action="button"
							theme="link"
							@click="close()"
						>
							{{ getTranslation('ShopRedirect.Cancel') }}
						</RenderAction>
						<RenderAction
							action="button"
							theme="primary"
							@click="redirectShop(changingShopRedirectLink)"
						>
							{{ getTranslation('ShopRedirect.Select') }}
						</RenderAction>
					</div>
				</div>
			</template>
		</Modal>
	</div>
</template>
