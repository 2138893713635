<script setup>
import {defineAsyncComponent, ref, onMounted, watch, toRef} from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { getTranslation } from '@/ts/utilities.ts'
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))
const ShowModal = defineAsyncComponent(() => import('@/components/ShowModal.vue'))

const props = defineProps({
	dataType: {
		type: String,
	},
	queryParameter: {
		type: String,
	},
	options: {
		type: Array,
	},
	preSelectedOptions: {
		type: Array,
	},
	default: {
		type: Object,
	},
	filterType: {
		type: String,
	},
	multiple: {
		type: Boolean,
		default: false
	},
	type: {
		type: String,
		default: 'dropdown'
	},
	infobox: {
		type: Object
	},
	label: {
		type: String,
		default: 'Label'
	}
})

const facetOptions =  toRef(props, 'options')

const emit = defineEmits(['updateFilter'])

// Rewrite the incoming data from options, so it fits the SelectElement component
const options = ref(props.options?.map((option) => {
	return Object.assign({
		id: option.id,
		name: option.name,
		thumbColor: option.thumbColor
	}, option)
}))

if (props.dataType === 'facets') {
	options.value = props.options?.map((option) => {
		return {
			name: option.name,
			value: option.value,
			count: option.productsCount,
		}
	})
}

const selectedOptions = ref([])
const listbox = ref('')
const open = ref(false)

const resetOptions = () => {
	selectedOptions.value = []
	console.log('resetted options', selectedOptions.value)
	saveOptions()
}

const toggleOptions = () => {
	if (props.type === 'dropdown') {
		open.value = !open.value
	}
}

function addOption(option) {
	if (isOptionSelected(option)) {
		console.log('removing option', option)
		selectedOptions.value = selectedOptions.value.filter((item) => item.value !== option.value)
	} else {
		console.log('adding option', option)
		selectedOptions.value.push(option)
	}
}

function selectOption(option) {
	console.log('setting a single option', [option])
	selectedOptions.value = [option]

	console.log('selected a single option, now saving')
	saveOptions()
}

const saveOptions = () => {
	if (open.value === true) {
		emit('updateFilter', props.filterType, selectedOptions.value, props.queryParameter)
		open.value = false
	}
}

const isOptionSelected = (option) =>{
	return selectedOptions.value.map(o => o.value).includes(option.value)
}

function keydownHandler(event) {
	if (!open.value) return

	if (event.key === 'Escape') {
		open.value = false
	}
}

function setIcon() {
	if (props.type === 'modal') {
		return ['fal', 'arrow-up-right-from-square']
	} else {
		if (!open.value) return ['fal', 'arrow-down']
		return ['fal', 'arrow-up']
	}
}

onMounted(() => {
	window.addEventListener('keydown', keydownHandler)
	if (props.preSelectedOptions?.length > 0) {
		console.log('preselected options', props.preSelectedOptions)
		selectedOptions.value = props.preSelectedOptions
	}
})

watch(() => facetOptions, (newOptions) =>{
	//Update options to match new available facet-options and count
	if (props.dataType === 'facets') {
		options.value = newOptions.value?.map((option) => {
			return Object.assign({
				name: option.name,
				value: option.value,
				count: option.productsCount,
				selected: option.selected
			})
		})
		//console.log(options.value.filter(o => o.selected))
		selectedOptions.value = options.value.filter(o => o.selected)
		//console.log('watch', newOptions)
	}
},{
	deep:true
})

</script>

<template>
	<div class="flex flex-col gap-2 min-w-60">
		<!-- Set a unique listbox ID, based on Vue component -->
		<div class="flex items-center justify-between">
			<label class="font-bold text-sm"
				   :for="'listbox'"
			>
				{{ props.label }}
			</label>
			<ShowModal
				v-if="infobox"
				:modal-title="props.infobox.heading"
				:modal-content="props.infobox.content"
				:button-options="{ action: 'button', theme: 'faded', size: 'sm' }"
			>
				<font-awesome-icon :icon="['fas', 'info-circle']" />
				{{ getTranslation('Info') }}
			</ShowModal>
		</div>

		<div class="relative"
			 ref="listbox"
			 v-click-outside="saveOptions"
		>
			<!-- TODO: Maybe make this a specific SelectBoxButton component -->
			<button type="button"
					:aria-label="props.label"
					class="relative w-full text-left border-2 bg-grey-light border-grey text-black outline-none hover:border-grey-dark focus:border-grey-dark pl-4 pr-10 py-2 line-clamp-1"
					:class="open ? 'rounded-t-sm' : 'rounded-sm'"
					@click="toggleOptions()"
			>
				<template v-if="selectedOptions.length > 0" v-for="(option, index) in selectedOptions" :key="index">
					<span v-if="option"
						  class="mr-1 [&:not(:last-of-type)]:after:content-[',']"
					>
						{{ option.name }}
					</span>
				</template>
				<template v-else>
					<slot></slot>
				</template>

				<font-awesome-icon
					:icon="setIcon()"
					aria-hidden="true"
					class="pointer-events-none absolute inset-y-3 right-4 flex items-center"
				/>
			</button>
			<div class="absolute z-30 w-full rounded-b-sm"
				 :class="{ 'hidden': !open }"
			>
				<ul v-if="options && options.length > 0"
					class="max-h-60 overflow-auto list-none bg-white border-x-2 border-grey text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
					:class="props.multiple ? '' : 'border-b-2'"
					tabindex="-1"
					aria-labelledby="listbox-label"
					:aria-activedescendant="'list-box-'"
					role="listbox"
				>
					<template v-for="(option, index) in options" :key="index">
						<li
							class="text-black relative cursor-default select-none py-3 px-3 hover:bg-grey"
							v-if="option"
							:id="'listbox-option-' + option.id"
							:class="{ 'bg-grey': isOptionSelected(option) }"
							role="option"
							@click="props.multiple ? addOption(option) : selectOption(option)"
						>
							<span class="flex items-center gap-3">
								<input type="checkbox"
								   v-if="props.multiple"
								   class="appearance-none peer relative h-5 w-5 shrink-0 rounded-full border-2 border-grey-light focus:ring-2 focus:ring-offset-2 ring-grey-dark"
								   :class="{ 'checked:bg-primary checked:border-primary rounded-sm': !option.thumbColor }"
								   :style="{ backgroundColor: option.thumbColor, borderColor: option.thumbColor }"
								   :checked="isOptionSelected(option)"
								>
								<font-awesome-icon
									:icon="['fal', 'check']"
									class="text-white absolute h-3 w-3 left-4 hidden peer-checked:block"
								/>

								<span class="flex justify-between w-full truncate">
									{{ option.name }}
									<span v-if="option.count">({{ option.count }})</span>
								</span>
							</span>
						</li>
					</template>
				</ul>
				<div v-if="type === 'dropdown' && props.multiple"
					 class="w-full p-2 bg-white rounded-b-sm border-2 border-b-2 border-grey flex justify-center gap-2">
					<!-- TODO: Add translation -->
					<RenderAction
						action="button"
						theme="outline"
						size="sm"
						:disabled="selectedOptions.length === 0"
						@click="resetOptions()">
						Nulstil
					</RenderAction>
					<!-- TODO: Add translation -->
					<RenderAction
						action="button"
						theme="primary"
						size="sm"
						@click="saveOptions()"
					>
						Gem valg
					</RenderAction>
				</div>
			</div>
		</div>
	</div>
</template>
