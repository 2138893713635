<script setup lang="ts">
import {defineAsyncComponent, ref, onMounted, onBeforeMount, watch, computed} from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {getTranslation, readMore, goTo} from '@/ts/utilities.ts'
import { GA4EcommerceItem } from '@/ts/Models/GA4EcommerceItem.ts'
import { trackToDatalayer } from '@/ts/Helpers/tracking-helper.ts'

import { ProductListViewData, Facets, Group } from '@/interfaces/ProductListViewInterface.ts'

const SelectElement = defineAsyncComponent(() => import('@/components/ui/form/SelectElement.vue'))
const ProductCard = defineAsyncComponent(() => import('@/components/ProductCard.vue'))
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))
const NotificationElement = defineAsyncComponent(() => import('@/components/ui/NotificationElement.vue'))
const Spinner = defineAsyncComponent(() => import('@/components/ui/decoration/Spinner.vue'))
const Pagination = defineAsyncComponent(() => import('@/components/Pagination.vue'))
const USP = defineAsyncComponent(() => import('@/components/ui/USP.vue'))
const FiltersModal = defineAsyncComponent(() => import('@/components/FiltersModal.vue'))

interface Props {
	pageId: number,
	groupId: string,
	search: boolean
}

const props = defineProps<Props>()

const productsViewContainer = ref()
const groupDescription = ref()
const isLoading = ref(false)
const products: ProductListViewData = ref([])
const productsError = ref()

const productListBoxes = ref([])

const group: Group = ref()
const facets = ref(<Facets>[])
const currentPageNumber = ref(1)
const totalProducts = ref(0)
const totalPages = ref(0)
const pageSize = ref(0)
let queryParams = ref()
let query = ref('')
let selectedFacetOptions = ref({})

const showMobileFiltersModal = ref(false)

const productOptions = JSON.stringify({
	'options': {
		'media': true,
		'dimensions': true,
		'delivery': false,
		'cylindo': true,
		'productDetailsSimple': true,
		'priceAndCampaign': true,
		'variantOptionsSimple': true,
		'moreOptions': false,
		'specifications': false,
		'usps': true
	},
	'listOptions': {
		'facets': true,
		'group': true
	}
})


const groupId = computed(() =>{
	return props.groupId ? `groupid=${props.groupId}` : ''
})
const updateProductListByFilter = (type: string, options: Array<Object>, queryParameter: string) => {
	createQuery(queryParameter, options)
	getProducts()
}

const updateProductListByNavigation = (pageNumber: number) => {
	setPageNumber(pageNumber, true)

	getProducts()
		.then(() => {
			goTo(productsViewContainer.value)
		})
}

const resetFilters = () =>{
	const url = new URL(window.location.origin + window.location.pathname)

	Object.keys(selectedFacetOptions.value).forEach((facetKey) =>{
		if(selectedFacetOptions.value[facetKey].length > 0){
			selectedFacetOptions.value[facetKey].forEach((selectedFacetOption) =>{
				url.searchParams.delete(facetKey, selectedFacetOption.value ? selectedFacetOption.value : selectedFacetOption)
			})
		}
	})
	selectedFacetOptions.value = {}
	currentPageNumber.value = 1
	url.searchParams.set('PageNum', currentPageNumber.value)
	queryParams.value = url.searchParams.toString()
	setHistory(url)
	getProducts()

}

const trackListItems = () =>{
	let ecommerceData: GA4EcommerceItem[] = []
	products.value.forEach((product, index) =>{
		let ga4EcomItem: GA4EcommerceItem = new GA4EcommerceItem()
		ga4EcomItem.item_id = product?.id
		ga4EcomItem.item_name = product?.name
		ga4EcomItem.item_list_id = product?.id
		ga4EcomItem.item_list_name = product?.name
		ga4EcomItem.item_brand = product?.brand
		//ga4EcomItem.item_category = product?.category;
		ga4EcomItem.item_image = product?.media?.images?.find(i => i.isPrimary)?.url
		ga4EcomItem.price = product?.priceAndCampaign.price.raw
		ga4EcomItem.currency = 'DKK'
		ga4EcomItem.index = (pageSize.value * (currentPageNumber.value - 1)) + index
		ga4EcomItem.item_variant = product?.variantId
		ecommerceData.push(ga4EcomItem)
	})

	trackToDatalayer({
		event:'view_item_list',
		ecommerce: {
			items: ecommerceData
		}
	})
}

const productEndPoint = ref('')

const setEndpoint = () => {
	let queryParamsValue = queryParams.value
	// Exclude "GroupID" from the query parameters
	const urlParams = new URLSearchParams(queryParamsValue)

	urlParams.delete('groupid')
	urlParams.delete('GroupID')
	urlParams.delete('GroupId')
	queryParamsValue = urlParams.toString()

	if (props.search) {
		productEndPoint.value = `/mobler-api/v2/products/productsearchfeed?${queryParamsValue}`
	} else {
		productEndPoint.value = `/mobler-api/v2/products/productlistfeed?${groupId.value}&pageSize=25&${queryParamsValue}`
	}
}

const getProducts = async () => {
	setEndpoint()
	isLoading.value = true

	await fetch(productEndPoint.value, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: productOptions
	})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok')
			}
			return response.json()
		})
		.then(data => {
			group.value = data.group
			facets.value = data.facets
			products.value = data.products
			productListBoxes.value = data.usps
			pageSize.value = data.pageSize
			totalPages.value = data.totalPages
			totalProducts.value = data.totalProducts
			trackListItems()
		})
		.catch(error => {
			productsError.value = error
			console.error('There has been a problem with your fetch operation:', error)
		})

	isLoading.value = false
}

const createQuery = (queryParameter: string, options: Array<Object>) => {
	const url = new URL(window.location.origin + window.location.pathname)
	selectedFacetOptions.value[queryParameter] = []
	options.forEach((option) => {
		selectedFacetOptions.value[queryParameter].push(option)
	})

	Object.keys(selectedFacetOptions.value).forEach((facetKey) =>{
		if(selectedFacetOptions.value[facetKey].length > 0){
			selectedFacetOptions.value[facetKey].forEach((selectedFacetOption) =>{
				url.searchParams.append(facetKey, selectedFacetOption.value ? selectedFacetOption.value : selectedFacetOption)
			})
		}
	})
	currentPageNumber.value = 1
	url.searchParams.set('PageNum', currentPageNumber.value)
	queryParams.value = url.searchParams.toString()
	setHistory(url)
}

const setHistory = (url) =>{
	console.log('setHistory', url)
	window.history.pushState({
		params: queryParams.value,
		pageNum: currentPageNumber.value
	}, '', url.toString())
}

const setPageNumber = (pageNumber, updateHistory = false) =>{
	currentPageNumber.value = pageNumber
	const url = new URL( window.location.href )
	url.searchParams.set('PageNum', currentPageNumber.value)
	queryParams.value = url.searchParams.toString()
	if(updateHistory){
		setHistory(url)
	}

}
const getPageNumber = () =>{
	let params = new URLSearchParams(document.location.search)
	const pageNum = params.get('PageNum')
	return pageNum ? parseInt(pageNum, 10) : 1
}

const setPreSelectedFacetOptions = () =>{
	const urlParams = new URLSearchParams(window.location.search)
	urlParams.forEach((value, key) =>{
		if(key.toLowerCase() !== 'pagenum'){
			if(!selectedFacetOptions.value[key]){
				selectedFacetOptions.value[key] = []
			}
			selectedFacetOptions.value[key].push(value)
		}
	})
}


let currentUspsIndex = 0;
const showUsp = (index) => {
	if(currentPageNumber.value > 1){
		return false;
	}
	if (products.value.length >= 9 && index === 6) {
		return true
	} else if (products.value.length >= 17 && index === 15) {
		return true
	} else if (products.value.length >= 25 && index === 22) {
		return true
	}
	return false
}
const getUsp = (index) => {
	if (index === 6) {
		currentUspsIndex = 0
		return productListBoxes.value[0]

	} else if (index === 15) {
		currentUspsIndex = 1
		return productListBoxes.value[1]
	} else if (index === 22) {
		currentUspsIndex = 2
		return productListBoxes.value[2]
	}
	return null
}

onBeforeMount(() => {
	const urlParams = new URLSearchParams(window.location.search)
	queryParams.value = urlParams.toString()
	query.value = urlParams.get('q') ?? ''

	setPreSelectedFacetOptions()
	currentPageNumber.value = getPageNumber()
	setPageNumber(currentPageNumber.value)
})

onMounted (async () => {
	await getProducts()

	window.addEventListener('popstate', () => {

		const urlParams = new URLSearchParams(window.location.search)
		queryParams.value = urlParams.toString()
		selectedFacetOptions.value = {}
		currentPageNumber.value = getPageNumber()
		setPageNumber(currentPageNumber.value)
		setPreSelectedFacetOptions()
		getProducts()
	}, {once: true})
})

watch( () => currentPageNumber, (newValue) =>{
	if(newValue.value === 1){

	}
}, {
	deep: true
})

</script>

<template>
	<div v-if="props.search"
		class="container ">
		<div class="flex flex-col">
			<h3>{{ getTranslation('Search.ResultsPage.Title') }}: "{{ query }}"</h3>
			<span>{{ totalProducts }} {{ getTranslation('Search.ResultsPage.AmountAppend') }}</span>
		</div>
	</div>
	<div v-if="group?.name"
		class="container">
		<div class="flex items-center">
			<h1>{{ group?.name }}</h1>
		</div>
	</div>
	<div ref="groupDescription"
		 class="container flex relative mb-6">
		<div class="js-read-more-container"
			:class="group?.readMore ? 'read-more-container' : ''">
			<div class="prose-sm lg:prose lg:max-w-none">
				<div v-html="group?.description"></div>
			</div>
		</div>
		<div class="read-more-fade container bg-grey-light js-read-more-fade"
			style="box-shadow: 0 -2rem 2.5rem 2.5rem rgb(237, 237, 237);"
			:class="group?.readMore ? '' : 'hidden'">
			<RenderAction
				action="button"
				theme="transparent"
				size="sm"
				@click="readMore($refs.groupDescription)"
			>
				{{ getTranslation('CollapseReadMore') }}
				<font-awesome-icon :icon="['fas', 'chevron-down']"/>
			</RenderAction>
		</div>
	</div>

	<div class="mb-4 flex container" ref="productsViewContainer">
		<div class="hidden md:flex md:flex-wrap gap-2">
			<SelectElement
				v-for="facet in facets"
				data-type="facets"
				:options="facet.facetOptions"
				:pre-selected-options="facet.facetOptions.filter(option => option.selected === true)"
				:query-parameter="facet.parametername"
				:multiple="true"
				:label="facet.name"
				@updateFilter="updateProductListByFilter"
				:key="facet.$id"
			>
				{{ facet.name }}
			</SelectElement>
		</div>
		<div class="flex flex-wrap grow md:hidden gap-2 my-2"
			v-if="facets && facets?.length > 0">
			<RenderAction
				action="button"
				theme="primary"
				@click="showMobileFiltersModal = true">
				<font-awesome-icon :icon="['fas', 'sliders']" />
				{{ getTranslation('Products.Filters.Header') }}
				<span v-if="Object.keys(selectedFacetOptions).length">({{Object.keys(selectedFacetOptions).length}})</span>
			</RenderAction>
		</div>
	</div>
	<FiltersModal
		:facets="facets"
		:show="showMobileFiltersModal"
		@close="showMobileFiltersModal = false"
		@updateFilter="updateProductListByFilter"
		@resetFilters="resetFilters"  />

	<Spinner v-if="isLoading" class="container min-h-[50vh]">
		{{ getTranslation('Products.Loading') }}
	</Spinner>
	<div v-else-if="productsError">
		{{ getTranslation('Products.Error') }} {{ productsError }}
	</div>
	<div v-else>
		<div v-if="products?.length > 0"
			class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 container pb-8 gap-6">

			<template v-for="(product, index) in products" :key="product.id">
				<ProductCard
					:product="product"
				/>

				<USP
					v-if="showUsp(index)"
					:usp="getUsp(index)"
					context="list"
					layout="card"
					:index="currentUspsIndex"
					:productCount="products.length"
				/>
			</template>
		</div>
		<div v-else
			class="flex justify-center items-center container">
			<NotificationElement
				class="py-8 mb-4">
				<template #content>
					{{ getTranslation('ProductListNoProducts') }}
				</template>
			</NotificationElement>
			<!-- Nustil filtre -->
		</div>
	</div>
	<Pagination
		:totalPages="totalPages"
		:pageSize="pageSize"
		:current-page="currentPageNumber"
		:max-pages-shown="10"
		@onPaging="updateProductListByNavigation" />

	<div class="bg-white">
		<div v-if="group?.groupDescriptionBottom"
			 class="container flex pb-8 mb-6 relative"
			 ref="groupDescriptionBottom">
			<div class="read-more-container js-read-more-container">
				<div class="prose-sm lg:prose lg:max-w-none">
					<div v-html="group?.groupDescriptionBottom"></div>
					<div v-html="group?.groupDescriptionBottomFoldout"></div>
				</div>
			</div>
			<div class="read-more-fade container bg-white js-read-more-fade"
				 style="box-shadow: 0 -2rem 2.5rem 2.5rem rgb(255, 255, 255);">
				<RenderAction
					action="button"
					theme="outline"
					size="sm"
					@click="readMore($refs.groupDescriptionBottom)"
				>
					{{ getTranslation('CollapseReadMore') }}
					<font-awesome-icon :icon="['fas', 'chevron-down']"/>
				</RenderAction>
			</div>
		</div>
	</div>
</template>

