<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
const RenderAction = defineAsyncComponent(() => import("@/components/helpers/RenderAction.vue"))

const props = defineProps({
	slides: {
		type: Object
	}
})

const splideOptions = {
	type      : 'loop',
	perPage   : 1,
	perMove   : 1,
	arrows: true,
	lazyLoad: 'sequential',
	drag: true,
	mediaQuery: 'min'
}

</script>

<template>
	<div>
		<Splide
			:has-track="false"
			:options="splideOptions">
			<SplideTrack>
				<SplideSlide v-for="slide in props.slides.slides" :key="slides.key">
					<img
						:data-splide-lazy="`/Admin/Public/GetImage.ashx?Image=${slide.image}&Width=400&height=250&Format=webP&Quality=90&Crop=5&resolution=50`"
						:data-splide-lazy-srcset="`/Admin/Public/GetImage.ashx?Image=${slide.image}&Width=400&height=250&Format=webP&Quality=90&Crop=5&resolution=50 400w, /Admin/Public/GetImage.ashx?Image=${slide.image}&Width=800&height=500&Format=webP&Quality=90&Crop=5&resolution=50 800w, /Admin/Public/GetImage.ashx?Image=${slide.image}&Width=1600&height=1000&Format=webP&Quality=90&Crop=5&resolution=50 1600w`"
						sizes="100vw"
						:alt="slide.title"
						class="rounded w-full aspect-[16/10] object-cover object-center max-h-[600px]">
					<div class="relative -mt-8 ml-6 mr-6 lg:-mt-40 grid grid-cols-1 lg:grid-cols-12">
						<div class="p-8 lg:p-12 rounded flex flex-col items-start basis-1/2 lg:col-span-6 lg:col-start-6"
							 :class="'bg-' + slide.background">
							<div class="flex flex-col gap-y-4 mb-4" v-html="slide.content"></div>
							<RenderAction
								v-if="slide.callToAction && slide.callToActionTitle"
								action="link"
								theme="dark"
								:data="slide.callToAction">
								{{ slide.callToActionTitle }}
							</RenderAction>
						</div>
					</div>
				</SplideSlide>
			</SplideTrack>

			<div class="splide__pagination relative hidden lg:flex flex-nowrap gap-x-2 justify-start -mt-12 p-0 bottom-0"></div>

			<div class="splide__arrows">
				<button class="splide__arrow splide__arrow--prev -left-4"><font-awesome-icon :icon="['fal', 'chevron-right']" /></button>
				<button class="splide__arrow splide__arrow--next -right-4"><font-awesome-icon :icon="['fal', 'chevron-right']" /></button>
			</div>
		</Splide>
	</div>
</template>

<style>
/* Overruling Splide defaults */
button.splide__pagination__page {
	@apply h-2 w-12 transition rounded-sm m-0 opacity-100 bg-grey hover:bg-grey-dark/90;
}
button.splide__pagination__page.is-active {
	@apply bg-grey-dark scale-100;
}
</style>
