<script setup lang="ts">
import {defineAsyncComponent, onMounted, ref} from 'vue'
import { getTranslation } from "@/ts/utilities.ts";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
const Modal = defineAsyncComponent(() => import('@/components/helpers/Modal.vue'))
const VideoBlock = defineAsyncComponent(() => import('@/components/blocks/VideoBlock.vue'))
import { VideoGallery } from '@/interfaces/ProductViewInterface.ts'
const RenderAction = defineAsyncComponent(() => import("@/components/helpers/RenderAction.vue"))


interface Props {
	data: VideoGallery
}

const props = defineProps<Props>()
const videos = ref<VideoGallery>(props.data)

const showModal = ref(false)

function openModal(index: number) {
	showModal.value = true
}

function close() {
	showModal.value = false
}
</script>

<template>
	<div class="container mb-8">
		<div class="flex flex-col gap-y-8">
			<div
				v-for="(video, index) in videos"
				:key="index"
				class="flex flex-col gap-8 lg:gap-16 bg-beige-light rounded overflow-hidden"
				:class="index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'">
				<div
					v-if="video.videoId"
					class="basis-1/2 lg:basis-2/3"
				>
					<VideoBlock
						:video="video.videoId"
						@click="() => video.videoModal ? openModal(index) : ''"
					/>
				</div>
				<div class="flex flex-col items-start justify-center gap-y-4 pb-12 px-8 lg:pb-0 lg:basis-1/3"
					:class="index % 2 === 0 ? 'lg:pl-0 lg:pr-12' : 'lg:pl-12 lg:pr-0'">
					<span
						v-if="video.subheading"
						class="font-bold text-sm text-primary"
					>
						{{ video.subheading }}
					</span>
					<h3
						v-if="video.heading"
						class="text-grey-dark"
					>
						{{ video.heading }}
					</h3>
					<div
						v-if="video.content"
						class="text-grey-dark" v-html="video.content"
					/>
					<RenderAction
						v-if="video.link && video.linkText"
						action="link"
						theme="primary"
						size="sm"
						:data="video.link"
					>
						{{ video.linkText }}
					</RenderAction>
				</div>
			</div>
		</div>
	</div>

 	<Modal
		:show="showModal"
		@close="close()"
	>
		<template v-slot:modal-title>
			<p class="text-xl md:text-2xl">Video Modal</p>
		</template>
		<template v-slot:content>
			<VideoBlock
				:video="videos[0].videoId"
			/>
		</template>
	</Modal>
</template>
