<script setup lang="ts">
import {defineAsyncComponent, ref} from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {getTranslation} from '@/ts/utilities.ts'
const RenderAction = defineAsyncComponent(() => import('@/components/helpers/RenderAction.vue'))
const PriceElement = defineAsyncComponent(() => import("@/components/PriceElement.vue"))
import {OrderLines, OrderLine} from "@/interfaces/OrderLineInterface.ts";

interface Props {
	product: OrderLines
	simple?: boolean
	padding?: boolean
}

const props = defineProps<Props>()

const orderLine: OrderLine = ref(props.product)
const emit = defineEmits(['deleteOrderLine', 'updateOrderLine', 'deleteVoucher'])

let productLineTranslations = ref()

productLineTranslations.value = {
	translationCartDeleteOrderline: getTranslation('Cart.DeleteItemCart')
}

</script>

<template>
	<div
		class="grid py-4 lg:p-4 relative border-b border-grey bg-white hover:bg-grey-light/25 hover:no-underline transition"
		:class="{
			'p-4': props.padding,
			'grid-cols-3 lg:grid-cols-7 gap-4 lg:p-4': !props.simple,
			'grid-cols-4 gap-x-2 gap-y-4 lg:p-6': props.simple
		}
		">
		<template v-if="orderLine?.orderlinePriceInfo?.isDiscountLine">
			<div
				:class="!props.simple ? 'lg:col-start-2 lg:col-span-2 flex' : ''">
				<p class="text-base font-bold">

<!--					{{ getTranslation('Cart.Discount')}}-->
				</p>
				<span class="bg-yellow inline uppercase w-fit p-2 text-sm font-bold mb-1 cursor-pointer"
					@click="emit('deleteVoucher')">
					{{ orderLine?.orderlinePriceInfo?.discountName }}
					<font-awesome-icon :icon="['fal', 'xmark']"/>
				</span>
			</div>
			<div class="flex items-center justify-end lg:col-start-6 col-span-2">
				<PriceElement
					theme="line"
					:price-data="orderLine?.orderlinePriceInfo"
				/>
			</div>
		</template>
		<template v-else>
			<div class="flex flex-col items-center gap-y-2"
				:class="props.simple ? 'col-span-2' : ''">
				<a class="flex justify-center align-center h-auto relative"
				   :href="orderLine?.product?.link"
				   :title="orderLine?.product?.name">
					<img class="object-contain w-full aspect-[4/3]"
						 v-if="orderLine?.product?.media?.images[0] || orderLine?.product?.media?.productImage"
						 :src="orderLine?.product?.cylindo?.isUsingCylindoAssets ?
						 	orderLine?.product?.media?.productImage :
						 	'/Admin/Public/GetImage.ashx?Image=' + orderLine?.product?.media?.images[0].url + '&Width=280&height=220&Format=webP&Quality=90&Crop=5&resolution=50'"
						 :alt="orderLine?.product?.name"
						 loading="lazy"
					/>
				</a>
				<RenderAction
					v-if="!props.simple"
					action="link"
					theme="link"
					size="none"
					class="text-sm"
					@click="emit('deleteOrderLine', product)"
				>
					<font-awesome-icon :icon="['fal', 'trash']"/>
					{{ productLineTranslations.translationCartDeleteOrderline }}
				</RenderAction>
			</div>

			<div class="flex flex-col"
				 :class="!props.simple ? 'col-span-2' : 'col-span-4'">
				<span
					v-if="orderLine?.product?.priceAndCampaign?.preOrder"
					class="text-sm font-bold uppercase mr-auto bg-primary-light py-1 px-2"
				>
					{{ getTranslation('PreOrder') }}
				</span>
				<a class="text-black hover:text-black hover:underline"
				   :href="orderLine?.product?.link">
					<p class="text-base font-bold"
						:class="!props.simple ? 'text-base' : 'text-sm'">{{ orderLine?.product?.name }}</p>
				</a>
				<div class="flex flex-col mb-2">
					<small
						class="mb-2 italic"
						v-if="!props.simple"
						>
						{{ getTranslation('ProductProductNumber') }} {{ orderLine?.product?.number }}</small>
					{{ orderLine?.variantText }}

					<ul v-if="orderLine?.product?.variantOptionsAdvanced?.variantGroups"
						class="*:text-sm">
						<li v-for="variantGroup in orderLine?.product?.variantOptionsAdvanced?.variantGroups">
							<!-- It can either be in variantGroup.selectedName, or as a selected boolean in a variantOptionDetails array -->
							{{ variantGroup.selectedName ? variantGroup.selectedName : variantGroup.variantOptionDetails.find(option => option.selected).name }}
						</li>
					</ul>
				</div>
				<span class="text-sm text-grey-dark" v-if="orderLine?.product?.delivery">
					{{ orderLine?.product?.delivery?.deliveryTime }}
				</span>
			</div>
			<!-- Quantity -->
			<div class="flex flex-col justify-center col-span-1 lg:col-span-2"
				 :class="!props.simple ? 'items-center' : ''">
				<div
					v-if="!props.simple"
					class="flex items-center mb-2">
					<div class="py-2 px-4 w-7 h-7 flex items-center justify-center bg-white border border-grey-light md:w-8 md:h-8 hover:cursor-pointer" @click="emit('updateOrderLine', product, (orderLine?.quantity - 1))">
						-
					</div>
					<p class="px-2">{{ orderLine?.quantity }}</p>
					<div class="py-2 px-4 w-7 h-7 flex items-center justify-center bg-white border border-grey-light md:w-8 md:h-8 hover:cursor-pointer" @click="emit('updateOrderLine', product, (orderLine?.quantity + 1))">
						+
					</div>
				</div>
				<p
					v-if="orderLine?.quantity > 1"
					class="text-sm">
					{{ orderLine?.quantity }}
					{{ getTranslation('Cart.Unit.Annotation') }} {{ orderLine?.product?.priceAndCampaign?.price?.formatted }}
				</p>
			</div>

			<div class="flex items-center justify-end col-span-2">
				<PriceElement
					theme="line"
					:price-data="orderLine?.orderlinePriceInfo"
				/>
			</div>
		</template>
	</div>
</template>
