﻿import {inject} from 'vue'
import {MarkerData} from '@/interfaces/MapsViewInterface.ts'

const getTranslation = ( key: string, defaultValue: string = '' ) => {
	const settings = inject('settings')
	const translations = settings.translations

	const translationEntry = translations.find(translation => translation.Key === key)
	if (translationEntry) {
		return translationEntry.Value ? translationEntry.Value : translationEntry.DefaultValue
	}
	console.warn(`Missing translation - "${key}"`)
	return key
}

// Get website settings
const getSettings = () => {
	return inject('settings')
}

// Get Navigation
const getNavigation = () => {
	return inject('navigation')
}

// Scroll to an element
// Link to the anchor with a ref, e.g. @click="goto($refs.productSpecifications)"
const goTo = (element: string) => {
	const el = element
	if (el) {
		el.scrollIntoView({ behavior: 'smooth' })
	}
}

// Read more functionality
const readMore = (element: HTMLElement) => {
	const readMoreContainer: HTMLElement | null = element.querySelector('.js-read-more-container')

	if (!readMoreContainer) return

	readMoreContainer.style.maxHeight = '9999px'
	readMoreContainer.style.transition = 'max-height 400ms ease-in-out'

	const readMoreFade: HTMLElement | null = element.querySelector('.js-read-more-fade')
	if (readMoreFade) {
		readMoreFade.style.display = 'none'
	}

}

// Get unique guid
const getGuid = () => {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	}
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

// Get user location based on the Geolocation API and pass it to Google Maps API to get the postal code
const getUserLocation = async () => {
	if (!navigator.geolocation) {
		console.log('Geolocation is not supported by your browser');
	} else {
		const { Geocoder } = await google.maps.importLibrary("geocoding")
		navigator.geolocation.getCurrentPosition((position) => {
			const lat = position.coords.latitude;
			const long = position.coords.longitude;

			// Hit internal endpoint to log successful location fetch
			fetch('/locationlog.aspx?accept=true').then(() => {
				console.log('Location true logged');
			})


			// Get the postal code from the user's location
			// new Geocoder().geocode({ location: { lat, lng: long } }, (results, status) => {
			// 	if (status == google.maps.GeocoderStatus.OK) {
			// 		if (results[0]) {
			// 			console.log(results[0].address_components.find((c) => c.types.includes('postal_code')).long_name)
			// 		}
			// 	} else {
			// 		console.log('Geocoder failed due to: ' + status);
			// 	}
			// });
		}, () => {
			console.log('Unable to retrieve your location');

			fetch('/locationlog.aspx?accept=false').then(() => {
				console.log('Location false logged');
			})
		});
	}
}

const setShop = async (dealer: MarkerData, isCheckout: Boolean) => {
	let dealerName
	if (dealer.MenuText) {
		dealerName = dealer.MenuText
	} else {
		dealerName = dealer
	}
	return await fetch(`mobler-api/v2/shops/setshop?ShopName=${dealerName}&IsAboutUsPage=false${isCheckout ? '&IsCheckoutPage=true' : ''}`)
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok')
			}
			return response.json()
		})
		.catch(error => {
			console.error('There has been a problem with your fetch operation:', error)
			throw error
		})
}

const setLogo = () => {
	inject('settings')

	let shopSettings = getSettings()

	let mobileLogoPath
	let logoPath
	let serviceText

	let shopname = shopSettings?.shopname?.toLowerCase()
	if (shopname && shopname != "møblér" && shopname != "mobler" && shopname != "m%c3%b8bl%c3%a9r") {
		mobileLogoPath = `/Files/Templates/Designs/Mobler/dist/logos/mobler-${shopname}-mobile.svg`
		logoPath = `/Files/Templates/Designs/Mobler/dist/logos/mobler-${shopname}.svg`

		if (!shopSettings?.isLocalshop) {
			serviceText = getTranslation('LogoServiceText')
		}
	} else {
		logoPath = "/Files/Templates/Designs/Mobler/dist/logos/mobler.svg"
		mobileLogoPath = "/Files/Templates/Designs/Mobler/dist/logos/mobler.svg"
	}

	return {mobileLogoPath, logoPath, serviceText}
}

export { getTranslation, getSettings, getNavigation, goTo, getGuid, getUserLocation, readMore, setShop, setLogo }
